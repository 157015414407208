var render = function () {
  var _vm$clientTracking;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Kupon',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "mb": "30px",
      "justify": "space-between",
      "align": "center",
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Manajemen Kupon ")]), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "border-radius": "16px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'admin.coupons.add'
        });
      }
    }
  }, [_vm._v(" Tambah Kupon ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "16px",
      "gap": "16px",
      "width": "100%",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" List Kupon ")]), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "62px",
      "placeholder": "Pencarian",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('c-menu', {
    attrs: {
      "close-on-select": true
    },
    on: {
      "blur": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "border-radius": "40px",
      "background-color": "white",
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px",
      "font-family": "Roboto",
      "font-weight": "normal",
      "display": ['none', 'flex']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "height": "30",
      "width": "30",
      "fill": "#888888"
    }
  })], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "300px"
    }
  }, [_c('c-box', {
    attrs: {
      "px": "24px",
      "mt": "24px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "24px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Start Date ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '::-webkit-calendar-picker-indicator': {
          background: "url(".concat(require('@/assets/icon-calendar-event.svg'), ") no-repeat center"),
          cursor: 'pointer'
        }
      },
      expression: "{\n                      '::-webkit-calendar-picker-indicator': {\n                        background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,\n                        cursor: 'pointer',\n                      },\n                    }"
    }],
    attrs: {
      "type": "date",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Start Date"
    },
    model: {
      value: _vm.startDate,
      callback: function callback($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "24px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" End Date ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '::-webkit-calendar-picker-indicator': {
          background: "url(".concat(require('@/assets/icon-calendar-event.svg'), ") no-repeat center"),
          cursor: 'pointer'
        }
      },
      expression: "{\n                      '::-webkit-calendar-picker-indicator': {\n                        background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,\n                        cursor: 'pointer',\n                      },\n                    }"
    }],
    attrs: {
      "type": "date",
      "error-border-color": "red.300",
      "placeholder": "Masukkan End Date"
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1)], 1)], 1), _c('c-menu-divider'), _c('c-flex', {
    attrs: {
      "justify": "flex-end"
    }
  }, [_c('c-button', {
    attrs: {
      "variant-color": "primary",
      "size": "sm",
      "mx": "24px",
      "my": "8px"
    },
    on: {
      "click": _vm.applyFilter
    }
  }, [_vm._v(" Apply ")])], 1)], 1)], 1)], 1), _c('BaseTable2', {
    attrs: {
      "columns": _vm.clientTracking.columns,
      "items": _vm.clientTracking.items,
      "page": _vm.page,
      "per-page": +_vm.perPage,
      "total-page": _vm.calculatePerPage((_vm$clientTracking = _vm.clientTracking) === null || _vm$clientTracking === void 0 ? void 0 : _vm$clientTracking.total, +_vm.perPage)
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.changePage(page_);
      },
      "on-change-per-page": function onChangePerPage(perPage_) {
        _vm.perPage = perPage_;

        _vm.onSearch();
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }