<template>
  <c-box flex-grow="1">
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        { label: 'Manajemen Kupon', isCurrent: true }
      ]"
    />
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-flex
        mb="30px"
        justify="space-between"
        align="center"
        width="100%"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          Manajemen Kupon
        </BaseText>
        <BaseButton
          :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          border-radius="16px"
          @click="$router.push({ name: 'admin.coupons.add' })"
        >
          Tambah Kupon
        </BaseButton>
      </c-flex>
      <c-box width="100%">
        <c-flex
          margin-bottom="16px"
          gap="16px"
          width="100%"
          align-items="center"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            white-space="nowrap"
          >
            List Kupon
          </BaseText>

          <c-box width="100%">
            <c-form-control
              pos="relative"
              w="100%"
            >
              <c-image
                :src="require('@/assets/ic-search.svg')"
                alt="icon"
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                left="15px"
                z-index="2"
              />
              <c-input
                id="search"
                v-model="search"
                type="text"
                w="100%"
                height="62px"
                placeholder="Pencarian"
                border-radius="20px"
                padding-left="50px"
                position="relative"
                z-index="1"
                @keyup="onSearch"
              />
            </c-form-control>
          </c-box>

          <c-menu
            :close-on-select="true"
            @blur="true"
          >
            <c-menu-button
              border-radius="40px"
              background-color="white"
              color="gray.900"
              font-size="14px"
              line-height="21px"
              font-family="Roboto"
              font-weight="normal"
              :display="['none', 'flex']"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-filter.svg')"
                height="30"
                width="30"
                fill="#888888"
              />
            </c-menu-button>
            <c-menu-list
              z-index="10"
              min-width="300px"
            >
              <c-box
                px="24px"
                mt="24px"
              >
                <c-form-control mb="24px">
                  <c-form-label
                    font-size="14px"
                    color="#555555"
                    font-weigh="400"
                    font-family="Roboto"
                  >
                    Start Date
                  </c-form-label>
                  <c-input-group size="md">
                    <c-input
                      v-model="startDate"
                      v-chakra="{
                        '::-webkit-calendar-picker-indicator': {
                          background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,
                          cursor: 'pointer',
                        },
                      }"
                      type="date"
                      error-border-color="red.300"
                      placeholder="Masukkan Start Date"
                    />
                  </c-input-group>
                </c-form-control>
                <c-form-control mb="24px">
                  <c-form-label
                    font-size="14px"
                    color="#555555"
                    font-weigh="400"
                    font-family="Roboto"
                  >
                    End Date
                  </c-form-label>
                  <c-input-group size="md">
                    <c-input
                      v-model="endDate"
                      v-chakra="{
                        '::-webkit-calendar-picker-indicator': {
                          background: `url(${require('@/assets/icon-calendar-event.svg')}) no-repeat center`,
                          cursor: 'pointer',
                        },
                      }"
                      type="date"
                      error-border-color="red.300"
                      placeholder="Masukkan End Date"
                    />
                  </c-input-group>
                </c-form-control>
              </c-box>
              <c-menu-divider />
              <c-flex justify="flex-end">
                <c-button
                  variant-color="primary"
                  size="sm"
                  mx="24px"
                  my="8px"
                  @click="applyFilter"
                >
                  Apply
                </c-button>
              </c-flex>
            </c-menu-list>
          </c-menu>
        </c-flex>

        <BaseTable2
          :columns="clientTracking.columns"
          :items="clientTracking.items"
          :page="page"
          :per-page="+perPage"
          :total-page="calculatePerPage(clientTracking?.total, +perPage)"
          @on-change-page="(page_) => changePage(page_)"
          @on-change-per-page="
            (perPage_) => {
              perPage = perPage_
              onSearch()
            }
          "
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseTable2 from '@/components/elements/base-table-2.vue'

export default {
  name: 'ManagementCouponsPage',
  components: {
    BaseTable2,
    BaseButton,
    BaseText,
    BreadcrumbPath,
  },
  mixins: [generalMixin],
  data() {
    return {
      clientTracking: {
        columns: [
          {
            id: 'no',
            label: 'NO',
            type: 'AUTO_INCREMENT',
          },
          {
            id: 'name',
            label: 'Nama Kupon',
            type: 'TEXT',
          },
          {
            id: 'code',
            label: 'Kode',
            type: 'TEXT',
          },
          {
            id: 'quota',
            label: 'Kuota',
            type: 'TEXT',
            itemAlign: 'end',
          },
          {
            id: 'start-end',
            label: 'start-end',
            type: 'TEXT',
            customRender: (item) => {
              return `${item.startAt} - ${item.endAt}`
            },
          },
          {
            id: 'potongan',
            label: 'potongan',
            type: 'TEXT',
            itemAlign: 'end',
            customRender: (item) => {
              return this.getDiscount(item)
            },
          },
          {
            id: 'aksi',
            label: 'aksi',
            type: 'BUTTON',
            itemAlign: 'end',
            customRender: (item) => {
              return {
                buttonText: 'Edit',
                onButton: {
                  click: () => {
                    this.$router.push({
                      name: 'admin.coupons.edit',
                      params: { id: item.id },
                    })
                  },
                },
                bindButton: {
                  variant: 'outlined',
                },
              }
            },
          },
        ],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
        },
        total: 0,
      },
      page: 1,
      perPage: '5',
      search: this.$route.query.q ?? '',
      startDate: '',
      endDate: '',
      timer: null,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1
        let params = new URLSearchParams()
        params.set('q', this.search)
        params.set('startDate', this.startDate)
        params.set('endDate', this.endDate)
        params.set('page', this.page)
        params.set('perpage', this.perPage)
        this.loadCoupons(params).then((listCoupon) => {
          this.clientTracking.items = listCoupon?.data
          this.clientTracking.total = listCoupon.meta.total
        })
      },
    },
  },
  methods: {
    ...mapActions({
      loadCoupons: 'admCoupons/listCouponsAdmin',
    }),
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    getDiscount(item) {
      if (item.type === 'percent') {
        return `${item.totalDiscount}%`
      }
      return `Rp${Number(item.totalDiscount).toLocaleString('id')}`
    },
    applyFilter() {
      this.onSearch()
      const container = document.querySelector('.container')
      if (container) {
        container.click()
      }
    },
    onSearch() {
      this.page = 1
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            q: this.search,
            startDate: this.startDate,
            endDate: this.endDate,
            page: this.page,
            perpage: this.perPage,
          },
        })
      }, 800)
    },
    changePage(page) {
      this.page = page
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
  },
}
</script>
